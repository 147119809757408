<svg
  xmlns="http://www.w3.org/2000/svg"
  role="presentation"
  class="hide-elem visually-hidden"
  aria-hidden="true"
>
  <symbol
    id="icon-home"
    viewBox="0 0 980 980"
    style="enable-background:new 0 0 980 980;"
  >
    <path
      d="M980,481c0,6.3,0,12.7,0,19c-0.6,1.4-1.6,2.8-1.9,4.3c-6.7,28.8-32.1,44.9-58.9,46.5c-18.8,1.1-37.6,0.3-56.5,0.3
      c-1.6,0-3.3,0-5.2,0c0,2.5,0,4.4,0,6.4c0,91,0.1,182-0.1,272.9c0,11.2-0.8,22.5-1.5,33.7c-0.9,16.3-5.7,31.7-12.7,46.2
      c-7.9,16.4-18.5,30.8-32.6,42.7c-14.1,11.8-29.9,19.8-47.7,23.9c-4.9,1.1-9.9,2-14.9,3c-0.7,0-1.3,0-2,0c-1.2-0.4-2.3-1-3.5-1
      c-53.5,0-107,0-160.5,0c-0.1-1.3-0.2-2.7-0.2-4c0-103.3,0-206.6,0-309.9c0-11.1-0.1-22.2-0.9-33.3c-0.6-8.8-4.4-15.6-14.6-17.8
      c-4.6-1-9.4-1.7-14.1-1.7c-44.6,0.1-89.2,0.4-133.9,0.7c-9.2,0.1-16.7,5.1-19,13.3c-1.4,5-2.2,10.3-2.2,15.4
      c-0.1,8.9,0.8,17.8,0.8,26.8c0.1,101.6,0,203.3,0,304.9c0,1.8-0.1,3.7-0.1,5.5c-52,0-103.9,0.1-155.9,0.1c-1.7,0-3.3,0.6-5,1
      c-1.7,0-3.3,0-5,0c-0.5-0.3-1-0.9-1.5-0.9c-15.6-0.8-29.8-5.8-43.3-13.4c-20.5-11.4-35.9-28-47.1-48.3
      c-12.8-23.2-17.9-48.1-17.8-74.7c0.5-95.2,0.2-190.3,0.2-285.5c0-1.9,0-3.9,0-6.2c-17.6,0-34.5,0-51.5,0c-9.2,0-18.3-0.1-27.2-3
      c-14.8-4.9-27-13.3-34.9-26.9c-3.8-6.6-6-14.1-8.9-21.1c0-6.7,0-13.3,0-20c0.3-0.2,0.7-0.3,0.8-0.5c2.9-18.9,12.3-34.9,24.5-49
      c12.5-14.4,26.1-27.8,39.3-41.6c11.8-12.2,23.7-24.2,35.5-36.4c13.2-13.8,26.3-27.7,39.5-41.5c15.9-16.7,31.9-33.3,47.9-50
      c17-17.7,34.1-35.4,51.1-53.1c16-16.6,32-33.3,47.9-49.9c16.9-17.6,33.8-35.2,50.8-52.8c12.6-13,25.2-26,37.9-38.9
      c13.5-13.7,26.7-27.8,41-40.5C430.1,13.6,446.9,6.5,465,3c4.7-0.9,9.3-2,14-3c6.7,0,13.3,0,20,0c4.3,1,8.5,2.3,12.9,2.9
      c14.8,2.2,28.3,7.7,41,15.4c12.3,7.5,22.5,17.4,32.2,28c16.2,17.6,33,34.6,49.6,51.8c17.2,17.8,34.3,35.7,51.5,53.5
      c16.5,17.1,32.9,34.3,49.4,51.4c16.9,17.6,33.8,35.2,50.8,52.8c12.7,13.1,25.6,26.1,38.3,39.3c13.1,13.6,26.1,27.4,39.1,41.1
      c15.8,16.6,31.7,33.1,47.6,49.6c14.6,15.2,29.3,30.3,43.6,45.8c7.7,8.3,14.2,17.7,18.1,28.5C975.6,467,977.7,474,980,481z"
    />
  </symbol>

  <symbol id="icon-anchor-link" viewBox="0 0 24 24">
    <title> Anchor link </title><g fill="currentColor">
      <path
        d="M13.222 3.322A5.5 5.5 0 0 1 21 11.1l-3.535 3.536a5.5 5.5 0 0 1-8.32-.629c-.242-.327-.174-.777.114-1.065.473-.473 1.307-.286 1.766.201.024.027.05.053.076.079a3.5 3.5 0 0 0 4.95 0l3.535-3.536a3.5 3.5 0 0 0-4.95-4.95l-1.06 1.061a1 1 0 1 1-1.415-1.414z"
      /><path
        d="M6.858 9.686c2.044-2.044 5.368-2.085 7.491-.263.383.328.352.906-.005 1.262-.419.42-1.112.355-1.602.022-1.344-.914-3.256-.82-4.47.394l-3.535 3.535a3.5 3.5 0 0 0 4.95 4.95l1.06-1.06a1 1 0 0 1 1.414 1.413L11.101 21a5.5 5.5 0 1 1-7.779-7.778z"
      /></g
    >
  </symbol>

  <symbol id="icon-blank-tab" viewBox="0 0 12 12">
    <title>Open link in new tab</title>
    <path
      fill="currentColor"
      d="M6 1h5v5L8.86 3.85 4.7 8 4 7.3l4.15-4.16L6 1ZM2 3h2v1H2v6h6V8h1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z"
    /></symbol
  >

  <symbol id="icon-directional-down-arrow" viewBox="0 0 33.4 13.9"
    ><path
      d="m1.5 1.5 15.2 10.9L31.9 1.5"
      style="fill:none;stroke:#444;stroke-width:3;stroke-linecap:round;stroke-linejoin:round"
    />
  </symbol>

  <symbol id="icon-search" viewBox="0 0 50 50">
    <title> Search Icon </title>
    <path
      d="M21 3C11.621 3 4 10.621 4 20s7.621 17 17 17c3.71 0 7.14-1.195 9.938-3.219l13.156 13.125 2.812-2.812-13-13.032A16.923 16.923 0 0 0 38 20c0-9.379-7.621-17-17-17Zm0 2c8.297 0 15 6.703 15 15s-6.703 15-15 15S6 28.297 6 20 12.703 5 21 5Z"
    />
  </symbol>
  <symbol
    id="phone-frame"
    x="0"
    y="0"
    viewBox="0 0 657.4 1297"
    style="enable-background:new 0 0 657.4 1297"
    xml:space="preserve"
    ><style>
    .st2 {
      fill: #a5acb2;
    }
    </style><g id="Phone_Details"
      ><g style="opacity:.1"
        ><path
          d="M657.4 299.2v-89.9c0-6.2-4.2-11.4-9.9-13v-69.2c0-55.9-45.3-101.2-101.2-101.2H128.4c-55.9 0-101.2 45.3-101.2 101.2v5.1c2.2-1.5 4.8-2.3 7.6-2.3H585c7.6 0 13.8 6.1 13.8 13.8v963c0 7.6-6.2 13.8-13.8 13.8H34.8c-2.8 0-5.4-.8-7.6-2.3v77.6c0 55.9 45.3 101.2 101.2 101.2h417.9c55.9 0 101.2-45.3 101.2-101.2v-742c5.7-1.6 9.9-6.8 9.9-13V351c0-6.2-4.2-11.4-9.9-13v-25.9c5.7-1.5 9.9-6.7 9.9-12.9z"
          style="fill:#585f65"
        /></g
      ><path
        class="st2"
        d="M623.8 288.7h-3.4V171.9h3.4c7.4 0 13.4 6 13.4 13.5v89.9c0 7.4-6 13.4-13.4 13.4zM623.8 430.4h-3.4V313.6h3.4c7.4 0 13.4 6 13.4 13.5V417c0 7.4-6 13.4-13.4 13.4z"
      /><path
        d="M529.9 0H90.5C40.5 0 0 40.5 0 90.5v1090c0 50 40.5 90.5 90.5 90.5h439.4c50 0 90.5-40.5 90.5-90.5V90.5c0-50-40.5-90.5-90.5-90.5zm68.7 1106.6c0 7.6-6.2 13.8-13.8 13.8h-550c-7.6 0-13.8-6.1-13.8-13.8v-963c0-7.6 6.2-13.8 13.8-13.8h550.1c7.6 0 13.8 6.1 13.8 13.8l-.1 963z"
        style="fill:#fff"
      /><path
        style="fill:none;stroke:#c1c7ce;stroke-width:12.0159;stroke-linecap:round;stroke-miterlimit:10"
        d="M236.6 60.7h100.3"
      /><circle cx="395.5" cy="60.9" r="8.2" style="fill:#c1c7ce" /><path
        d="M393.9 1173.3H197.5c-9.8 0-17.7 7.9-17.7 17.7h0c0 9.8 7.9 17.7 17.7 17.7H394c9.8 0 17.7-7.9 17.7-17.7h0c-.1-9.7-8-17.7-17.8-17.7z"
        style="fill:none;stroke:#c1c7ce;stroke-width:4.4101;stroke-linecap:round;stroke-miterlimit:10"
      /><path
        d="M584.9 135.3c4.6 0 8.4 3.8 8.4 8.4v963c0 4.6-3.8 8.4-8.4 8.4H34.8c-4.6 0-8.4-3.8-8.4-8.4v-963c0-4.6 3.8-8.4 8.4-8.4h550.1m0-5.4H34.8c-7.6 0-13.8 6.1-13.8 13.8v963c0 7.6 6.2 13.8 13.8 13.8h550.1c7.6 0 13.8-6.1 13.8-13.8v-963c-.1-7.6-6.2-13.8-13.8-13.8z"
        style="fill:#dde3ea"
      /></g
    ></symbol
  >

  <symbol id="google-play-button" viewBox="0 0 135 40"
    ><path
      d="M130 40H5c-2.8 0-5-2.2-5-5V5c0-2.8 2.2-5 5-5h125c2.8 0 5 2.2 5 5v30c0 2.8-2.2 5-5 5z"
    /><path
      fill="#a6a6a6"
      d="M130 .8c2.3 0 4.2 1.9 4.2 4.2v30c0 2.3-1.9 4.2-4.2 4.2H5C2.7 39.2.8 37.3.8 35V5C.8 2.7 2.7.8 5 .8h125m0-.8H5C2.2 0 0 2.3 0 5v30c0 2.8 2.2 5 5 5h125c2.8 0 5-2.2 5-5V5c0-2.7-2.2-5-5-5z"
    /><path
      d="M47.4 10.2c0 .8-.2 1.5-.7 2-.6.6-1.3.9-2.2.9-.9 0-1.6-.3-2.2-.9-.6-.6-.9-1.3-.9-2.2 0-.9.3-1.6.9-2.2.6-.6 1.3-.9 2.2-.9.4 0 .8.1 1.2.3.4.2.7.4.9.7l-.5.5c-.4-.5-.9-.7-1.6-.7-.6 0-1.2.2-1.6.7-.5.4-.7 1-.7 1.7s.2 1.3.7 1.7c.5.4 1 .7 1.6.7.7 0 1.2-.2 1.7-.7.3-.3.5-.7.5-1.2h-2.2v-.8h2.9v.4zM52 7.7h-2.7v1.9h2.5v.7h-2.5v1.9H52v.8h-3.5V7H52v.7zM55.3 13h-.8V7.7h-1.7V7H57v.7h-1.7V13zM59.9 13V7h.8v6h-.8zM64.1 13h-.8V7.7h-1.7V7h4.1v.7H64V13zM73.6 12.2c-.6.6-1.3.9-2.2.9-.9 0-1.6-.3-2.2-.9-.6-.6-.9-1.3-.9-2.2s.3-1.6.9-2.2c.6-.6 1.3-.9 2.2-.9.9 0 1.6.3 2.2.9.6.6.9 1.3.9 2.2 0 .9-.3 1.6-.9 2.2zm-3.8-.5c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7.4-.4.7-1 .7-1.7s-.2-1.3-.7-1.7c-.4-.4-1-.7-1.6-.7-.6 0-1.2.2-1.6.7-.4.4-.7 1-.7 1.7s.2 1.3.7 1.7zM75.6 13V7h.9l2.9 4.7V7h.8v6h-.8l-3.1-4.9V13h-.7z"
      style="fill:#fff;stroke:#fff;stroke-width:.2;stroke-miterlimit:10;"
    /><path
      fill="#fff"
      d="M68.1 21.8c-2.4 0-4.3 1.8-4.3 4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.8 4.3-4.3c0-2.6-1.9-4.3-4.3-4.3zm0 6.8c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.4 1 2.4 2.6 0 1.5-1.1 2.6-2.4 2.6zm-9.3-6.8c-2.4 0-4.3 1.8-4.3 4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.8 4.3-4.3c0-2.6-1.9-4.3-4.3-4.3zm0 6.8c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.4 1 2.4 2.6 0 1.5-1.1 2.6-2.4 2.6zm-11.1-5.5v1.8H52c-.1 1-.5 1.8-1 2.3-.6.6-1.6 1.3-3.3 1.3-2.7 0-4.7-2.1-4.7-4.8s2.1-4.8 4.7-4.8c1.4 0 2.5.6 3.3 1.3l1.3-1.3c-1.1-1-2.5-1.8-4.5-1.8-3.6 0-6.7 3-6.7 6.6 0 3.6 3.1 6.6 6.7 6.6 2 0 3.4-.6 4.6-1.9 1.2-1.2 1.6-2.9 1.6-4.2 0-.4 0-.8-.1-1.1h-6.2zm45.4 1.4c-.4-1-1.4-2.7-3.6-2.7s-4 1.7-4 4.3c0 2.4 1.8 4.3 4.2 4.3 1.9 0 3.1-1.2 3.5-1.9l-1.4-1c-.5.7-1.1 1.2-2.1 1.2s-1.6-.4-2.1-1.3l5.7-2.4-.2-.5zm-5.8 1.4c0-1.6 1.3-2.5 2.2-2.5.7 0 1.4.4 1.6.9l-3.8 1.6zM82.6 30h1.9V17.5h-1.9V30zm-3-7.3c-.5-.5-1.3-1-2.3-1-2.1 0-4.1 1.9-4.1 4.3s1.9 4.2 4.1 4.2c1 0 1.8-.5 2.2-1h.1v.6c0 1.6-.9 2.5-2.3 2.5-1.1 0-1.9-.8-2.1-1.5l-1.6.7c.5 1.1 1.7 2.5 3.8 2.5 2.2 0 4-1.3 4-4.4V22h-1.8v.7zm-2.2 5.9c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.3 1.1 2.3 2.6s-1 2.6-2.3 2.6zm24.4-11.1h-4.5V30h1.9v-4.7h2.6c2.1 0 4.1-1.5 4.1-3.9s-2-3.9-4.1-3.9zm.1 6h-2.7v-4.3h2.7c1.4 0 2.2 1.2 2.2 2.1-.1 1.1-.9 2.2-2.2 2.2zm11.5-1.8c-1.4 0-2.8.6-3.3 1.9l1.7.7c.4-.7 1-.9 1.7-.9 1 0 1.9.6 2 1.6v.1c-.3-.2-1.1-.5-1.9-.5-1.8 0-3.6 1-3.6 2.8 0 1.7 1.5 2.8 3.1 2.8 1.3 0 1.9-.6 2.4-1.2h.1v1h1.8v-4.8c-.2-2.2-1.9-3.5-4-3.5zm-.2 6.9c-.6 0-1.5-.3-1.5-1.1 0-1 1.1-1.3 2-1.3.8 0 1.2.2 1.7.4-.2 1.2-1.2 2-2.2 2zm10.5-6.6l-2.1 5.4h-.1l-2.2-5.4h-2l3.3 7.6-1.9 4.2h1.9l5.1-11.8h-2zm-16.8 8h1.9V17.5h-1.9V30z"
    /><g
      ><linearGradient
        id="SVGID_1_"
        x1="21.7996"
        x2="5.0172"
        y1="173.2904"
        y2="156.508"
        gradientTransform="matrix(1 0 0 -1 0 182.0002)"
        gradientUnits="userSpaceOnUse"
        ><stop offset="0" stop-color="#00a0ff" /><stop
          offset=".006574"
          stop-color="#00a1ff"
        /><stop offset=".2601" stop-color="#00beff" /><stop
          offset=".5122"
          stop-color="#00d2ff"
        /><stop offset=".7604" stop-color="#00dfff" /><stop
          offset="1"
          stop-color="#00e3ff"
        /></linearGradient
      ><path
        fill="url(#SVGID_1_)"
        d="M10.4 7.5c-.3.3-.4.8-.4 1.4V31c0 .6.2 1.1.5 1.4l.1.1L23 20.1v-.2L10.4 7.5z"
      /><linearGradient
        id="SVGID_2_"
        x1="33.8343"
        x2="9.6374"
        y1="161.9987"
        y2="161.9987"
        gradientTransform="matrix(1 0 0 -1 0 182.0002)"
        gradientUnits="userSpaceOnUse"
        ><stop offset="0" stop-color="#ffe000" /><stop
          offset=".4087"
          stop-color="#ffbd00"
        /><stop offset=".7754" stop-color="orange" /><stop
          offset="1"
          stop-color="#ff9c00"
        /></linearGradient
      ><path
        fill="url(#SVGID_2_)"
        d="M27 24.3l-4.1-4.1V19.9l4.1-4.1.1.1 4.9 2.8c1.4.8 1.4 2.1 0 2.9l-5 2.7z"
      /><linearGradient
        id="SVGID_3_"
        x1="24.8269"
        x2="2.0686"
        y1="159.704"
        y2="136.9457"
        gradientTransform="matrix(1 0 0 -1 0 182.0002)"
        gradientUnits="userSpaceOnUse"
        ><stop offset="0" stop-color="#ff3a44" /><stop
          offset="1"
          stop-color="#c31162"
        /></linearGradient
      ><path
        fill="url(#SVGID_3_)"
        d="M27.1 24.2L22.9 20 10.4 32.5c.5.5 1.2.5 2.1.1l14.6-8.4"
      /><linearGradient
        id="SVGID_4_"
        x1="7.2972"
        x2="17.4598"
        y1="181.8239"
        y2="171.6614"
        gradientTransform="matrix(1 0 0 -1 0 182.0002)"
        gradientUnits="userSpaceOnUse"
        ><stop offset="0" stop-color="#32a071" /><stop
          offset=".0685"
          stop-color="#2da771"
        /><stop offset=".4762" stop-color="#15cf74" /><stop
          offset=".8009"
          stop-color="#06e775"
        /><stop offset="1" stop-color="#00f076" /></linearGradient
      ><path
        fill="url(#SVGID_4_)"
        d="M27.1 15.8L12.5 7.5c-.9-.5-1.6-.4-2.1.1L22.9 20l4.2-4.2z"
      /><path
        d="M27 24.1l-14.5 8.2c-.8.5-1.5.4-2 0l-.1.1.1.1c.5.4 1.2.5 2 0L27 24.1z"
        opacity=".2"
      /><path
        d="M10.4 32.3c-.3-.3-.4-.8-.4-1.4v.1c0 .6.2 1.1.5 1.4v-.1h-.1zM32 21.3l-5 2.8.1.1 4.9-2.8c.7-.4 1-.9 1-1.4 0 .5-.4.9-1 1.3z"
        style="opacity:.12;enable-background:new;"
      /><path
        fill="#fff"
        d="M12.5 7.6L32 18.7c.6.4 1 .8 1 1.3 0-.5-.3-1-1-1.4L12.5 7.5c-1.4-.8-2.5-.2-2.5 1.4V9c0-1.5 1.1-2.2 2.5-1.4z"
        opacity=".25"
      /></g
    ></symbol
  >
</svg>
