/**
 * All of these values are used throughout the site – for example, 
 * in the <meta> tags, in the footer, and in the RSS feed.
 * 
 * PLEASE BE SURE TO UPDATE THEM ALL! Thank you!
 **/ 

export const siteTitle = 'Pogo LT'
export const siteDescription = 'Built with the SvelteKit Static Blog Starter'
export const siteURL = 'pogo-lt.org'
export const siteLink = 'https://github.com/josh-collinsworth/sveltekit-blog-starter'
export const siteAuthor = '- find and change this text in src/lib/config.js'

// Controls how many posts are shown per page on the main blog index pages
export const postsPerPage = 10

// Edit this to alter the main nav menu. (Also used by the footer and mobile nav.)
export const navItems = [
	{
		title: 'Home',
		route: '/'
	}, {
		title: 'News',
		route: '/news'
	}, {
		title: 'Support',
		route: '/support' 
	},
	{
		title: 'About',
		route: '/about' 
	},
]