<script>
// @ts-nocheck

import Nav from "$lib/components/nav.svelte";
import Footer from "$lib/components/footer.svelte";
import SvgGraphics from "$lib/components/svg-graphics.svelte";
import { preloadData } from "$app/navigation";
import { navItems } from "$lib/config";
import { afterNavigate, beforeNavigate } from "$app/navigation";
import { fade } from "svelte/transition";
import { cubicIn, cubicOut } from "svelte/easing";
import { onMount } from "svelte";
import { page } from "$app/stores";
import { redirect } from "@sveltejs/kit";

import { error } from "@sveltejs/kit";

export let data;

$: loading = false;
$: pathName = $page.url.pathname;

function scrollToSection(pHash, pBehavior) {
  let element = document.querySelector(pHash);
  let headerOffset = document.querySelector("header").offsetHeight - 5;
  let elemPosition = element.getBoundingClientRect().top;
  let offsetPosition = elemPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: pBehavior,
  });
}

afterNavigate((navigation) => {
  loading = false;

  if (!navigation.to.url.hash) {
    window.scrollTo({ top: 0 });
  }

  if ($page.url.hash) {
    scrollToSection($page.url.hash, "auto");
  }
});

beforeNavigate(async ({ to, from, cancel }) => {
  let destinationURL = to.url.origin;
  let websiteURL = window.location.origin;

  if (destinationURL === websiteURL) {
    loading = true;
  } else {
    loading = false;
  }
});

onMount(() => {
  const navRoutes = navItems.map((item) => item.route);

  document.querySelectorAll('a[href^="/#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      if (pathName === "/") {
        e.preventDefault();
        scrollToSection(this.getAttribute("href").replace("/", ""), "smooth");
      }
    });
  });
});
</script>

<Nav {...data.navigation} />

{#if loading}
  <div
    class="loading-screen"
    in:fade={{ duration: 100, easing: cubicOut }}
    out:fade={{ duration: 150, easing: cubicIn }}
  />
{/if}

<main>
  <slot />
</main>

{#await data.footer then footer}
  <Footer section={footer} />
{/await}

<SvgGraphics />

{@html data.analytics.analytics}

<style>
@import "../styles/base.css";
@import "../styles/swiper.min.css";

.loading-screen {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
</style>
