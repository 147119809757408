<script>
export let section;
</script>

{#if section.footer_nav}
  <footer>
    <div class="footer__inner">
      <div class="footer__content">
        <div class="footer__logo">
          <a href="/" title="Go to Pogo LT home" rel="internal">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352.8 64.8"
            >
              <title>Pogo LT</title>
              <path
                style="fill:#873731;"
                d="M338,56.63h-1.82v-1.5h5.38v1.5h-1.84v4.66H338Zm4.54-1.5h1.8l1.48,2.38,1.46-2.38h1.8v6.16h-1.68V57.67l-1.56,2.46h-.08l-1.56-2.46v3.62h-1.66Z"
              />
              <path
                style="fill:#873731;"
                d="M3.75,3.51h26c15.35,0,25.83,6.85,25.83,20.55v.17c0,14-10.64,21.38-26.24,21.38H23.07V61.29H3.75ZM28.27,31.82c4.95,0,8.25-2.23,8.25-6.35V25.3c0-4.21-3.06-6.35-8.17-6.35H23.07V31.82Z"
              />
              <path
                style="fill:#873731;"
                d="M57.62,32.57V32.4c0-16.75,13.79-30.13,31.62-30.13s31.44,13.21,31.44,30v.17c0,16.75-13.78,30.12-31.61,30.12S57.62,49.32,57.62,32.57Zm43.58,0V32.4c0-6.85-4.7-13-12.13-13s-12,6.11-12,12.87v.17c0,6.85,4.71,13,12.14,13S101.2,39.33,101.2,32.57Z"
              />
              <path
                style="fill:#873731;"
                d="M123.46,32.57V32.4c0-17.25,13.62-30.13,31.86-30.13A35.81,35.81,0,0,1,179.5,11l-10.81,13a19.4,19.4,0,0,0-12.79-5c-7.51,0-13,5.78-13,13.62v.16c0,8.17,5.7,13.79,13.78,13.79,3.31,0,5.12-.5,6.61-1.33V39.5h-9.91V27.12h28V53.86a40,40,0,0,1-25.5,8.66C137.91,62.52,123.46,50.48,123.46,32.57Z"
              />
              <path
                style="fill:#873731;"
                d="M185.32,32.57V32.4c0-16.75,13.78-30.13,31.61-30.13s31.44,13.21,31.44,30v.17c0,16.75-13.78,30.12-31.61,30.12S185.32,49.32,185.32,32.57Zm43.58,0V32.4c0-6.85-4.71-13-12.14-13s-12,6.11-12,12.87v.17c0,6.85,4.7,13,12.13,13S228.9,39.33,228.9,32.57Z"
              />
              <path
                style="fill:#873731;"
                d="M270.84,3.51h4.29V57.33h33.93v4H270.84Z"
              />
              <path
                style="fill:#873731;"
                d="M319.44,7.47H299.3v-4h44.57v4H323.73V61.29h-4.29Z"
              />
            </svg>
          </a>
        </div>

        <ul class="footer__navigation">
          {#each section.footer_nav.items as link}
            <li class="footer__navigation__item">
              {#if link.navigationItemUrl.linkType == "internal"}
                <a
                  rel="internal"
                  class="footer__navigation__link"
                  href="/{link.navigationItemUrl.internalLink.handle.current}"
                  title="Go to {link.text} page"
                >
                  <span>{link.text}</span>
                </a>
              {/if}

              {#if link.navigationItemUrl.linkType == "external"}
                <a
                  href={link.navigationItemUrl.href}
                  target={link.navigationItemUrl.blank ? "_blank" : null}
                  rel="external"
                  class="footer__navigation__link"
                  title="Go to external link at {link.navigationItemUrl.href}"
                >
                  <span>{link.text}</span>
                </a>
              {/if}
            </li>
          {/each}
        </ul>
      </div>

      <div class="footer__credit">
        <p>{section.credit}</p>
      </div>
    </div>
  </footer>
{/if}

<style>
footer {
  background-color: transparent;
}
footer .footer__inner {
  padding: 1rem;
  padding-bottom: 0;
}
@media only screen and (min-width: 768px) {
  footer .footer__inner {
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media only screen and (min-width: 961px) {
  footer .footer__inner {
    max-width: 71rem;
    margin-right: auto;
    margin-left: auto;
  }
}

footer .footer__logo {
  width: 9.375rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2.25rem;
}
@media only screen and (min-width: 768px) {
  footer .footer__logo {
    width: 8rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-bottom: 3rem;
  }
}

footer .footer__navigation {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 768px) {
  footer .footer__navigation {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-bottom: 2.25rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 961px) {
  footer .footer__navigation {
    width: 62rem;
  }
}
footer .footer__navigation .footer__navigation__item {
  margin-bottom: 2.25rem;
}
@media only screen and (min-width: 768px) {
  footer .footer__navigation .footer__navigation__item {
    margin-bottom: 0;
  }
}

.footer__navigation li {
  list-style: none;
}

.footer__navigation__link {
  color: var(--primary-color);
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2 cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: var(--h4);
  line-height: var(--body-line-height);
  position: relative;
}

.footer__navigation__link:after {
  content: " ";
  height: 2px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -0.15rem;
  background-color: #82342f;
  position: absolute;
  width: 0%;
  transition: all 0.2s ease-in-out;
}

.footer__navigation__link:hover:after,
.footer__navigation__link:focus:after {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .footer__navigation__link {
    font-size: var(--h6);
    line-height: var(--body-line-height);
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  footer .footer__navigation .footer__navigation__item:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1500px) {
  .footer__navigation__link {
    font-size: var(--h5);
  }
}

footer .footer__content,
footer .footer__footer {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  footer .footer__content {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 961px) {
  footer .footer__content {
    margin-bottom: 0;
    margin-top: 3.25rem;
    margin-bottom: 3.375rem;
  }
}
footer .footer__footer {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  footer .footer__footer {
    text-align: left;
  }
}
footer .footer__footer .footer__footer__link {
  color: #595757;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.5;
}
@media only screen and (min-width: 768px) {
  footer .footer__footer .footer__footer__link {
    margin-bottom: 0;
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
footer .footer__credit {
  width: 100%;
  text-align: center;
  color: var(--quintenary-color);
  padding-left: 0.5rem;
  font-size: var(--text-mini);
  line-height: var(--body-line-height);
  font-family: var(--tertiary-font-family);
}

.footer__credit p {
  margin-bottom: 0;
}
</style>
