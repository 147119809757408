<script>
// @ts-nocheck

import { trapFocus, removeTrapFocus } from "$lib/trapFocus";
import { page } from "$app/stores";
import { onMount } from "svelte";

export let main_nav;
let navLinks = main_nav.items;

let container;

//
// Menu Drawer JS
//
let navIsOpen = false;
let toggleText = "Open Menu";
let drawerMenuContainer;
let menuDrawerToggle;
let showNavItems = false;
let isSupportPage = false;

$: navIsOpen;
$: toggleText;
$: showNavItems;

$: pathName = $page.url.pathname;

$: isSupportPage = pathName.includes("support");

let toggleMenuDrawer = function (event) {
  navIsOpen = !navIsOpen;

  if (navIsOpen) {
    openMenuDrawer(event, drawerMenuContainer);
  } else {
    event.preventDefault();
    closeMenuDrawer(event, menuDrawerToggle);
  }
};

let openMenuDrawer = function (event, elementToFocus = false) {
  // drawerMenuContainer.querySelector('aside').style.height = `calc(101vh - ${
  //   document.querySelector('header').clientHeight + 'px'
  // })`;
  // drawerMenuContainer.querySelector('aside').style.top =
  //   document.querySelector('header').clientHeight + 1 + 'px';
  setTimeout(() => {
    showNavItems = !showNavItems;
  }, 100);
  trapFocus(elementToFocus);
};

let closeMenuDrawer = function (event, elementToFocus = false) {
  navIsOpen = navIsOpen ? !navIsOpen : !!navIsOpen;
  setTimeout(() => {
    showNavItems = !showNavItems;
  }, 0);
  removeTrapFocus(elementToFocus);
  drawerMenuContainer.querySelector("aside").removeAttribute("style");
};

let onKeyUp = (event) => {
  if (event.code.toUpperCase() !== "ESCAPE") return;

  const openDetailsElement = event.target.closest("details[open]");
  if (!openDetailsElement) return;

  openDetailsElement === drawerMenuContainer
    ? closeMenuDrawer(event, menuDrawerToggle)
    : closeSubMenu(openDetailsElement);
};

onMount(async () => {
  await import("$lib/sticky-header");

  container.querySelectorAll("a").forEach((event) => {
    event.addEventListener("click", (e) => {
      setTimeout(() => {
        closeMenuDrawer(e);
      }, 400);
    });
  });
});
</script>

<sticky-header class="header-container" bind:this={container}>
  <header
    class:is-open={navIsOpen}
    class:is-support={isSupportPage}
    bind:this={drawerMenuContainer}
  >
    <div class="header__inner">
      <div class="header__logo" data-ui-component="header-logo">
        <h1>
          <a href="/" rel="internal" title="Go to Pogo LT Home">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352.8 64.8"
            >
              <title>Pogo LT</title>
              <path
                style="fill:#873731;"
                d="M338,56.63h-1.82v-1.5h5.38v1.5h-1.84v4.66H338Zm4.54-1.5h1.8l1.48,2.38,1.46-2.38h1.8v6.16h-1.68V57.67l-1.56,2.46h-.08l-1.56-2.46v3.62h-1.66Z"
              />
              <path
                style="fill:#873731;"
                d="M3.75,3.51h26c15.35,0,25.83,6.85,25.83,20.55v.17c0,14-10.64,21.38-26.24,21.38H23.07V61.29H3.75ZM28.27,31.82c4.95,0,8.25-2.23,8.25-6.35V25.3c0-4.21-3.06-6.35-8.17-6.35H23.07V31.82Z"
              />
              <path
                style="fill:#873731;"
                d="M57.62,32.57V32.4c0-16.75,13.79-30.13,31.62-30.13s31.44,13.21,31.44,30v.17c0,16.75-13.78,30.12-31.61,30.12S57.62,49.32,57.62,32.57Zm43.58,0V32.4c0-6.85-4.7-13-12.13-13s-12,6.11-12,12.87v.17c0,6.85,4.71,13,12.14,13S101.2,39.33,101.2,32.57Z"
              />
              <path
                style="fill:#873731;"
                d="M123.46,32.57V32.4c0-17.25,13.62-30.13,31.86-30.13A35.81,35.81,0,0,1,179.5,11l-10.81,13a19.4,19.4,0,0,0-12.79-5c-7.51,0-13,5.78-13,13.62v.16c0,8.17,5.7,13.79,13.78,13.79,3.31,0,5.12-.5,6.61-1.33V39.5h-9.91V27.12h28V53.86a40,40,0,0,1-25.5,8.66C137.91,62.52,123.46,50.48,123.46,32.57Z"
              />
              <path
                style="fill:#873731;"
                d="M185.32,32.57V32.4c0-16.75,13.78-30.13,31.61-30.13s31.44,13.21,31.44,30v.17c0,16.75-13.78,30.12-31.61,30.12S185.32,49.32,185.32,32.57Zm43.58,0V32.4c0-6.85-4.71-13-12.14-13s-12,6.11-12,12.87v.17c0,6.85,4.7,13,12.13,13S228.9,39.33,228.9,32.57Z"
              />
              <path
                style="fill:#873731;"
                d="M270.84,3.51h4.29V57.33h33.93v4H270.84Z"
              />
              <path
                style="fill:#873731;"
                d="M319.44,7.47H299.3v-4h44.57v4H323.73V61.29h-4.29Z"
              />
            </svg>
          </a>
        </h1>
      </div>

      <button
        class="header__nav-toggle"
        data-ui-component="header-nav-toggle"
        aria-expanded={navIsOpen}
        class:is-active={navIsOpen}
        bind:this={menuDrawerToggle}
        on:click={toggleMenuDrawer}
      >
        <span aria-hidden="true" class="header__nav-toggle-icon" />
        <span class="visuallyHidden" data-toggle-text>
          {#if navIsOpen}
            Close Menu
          {:else}
            Open Menu
          {/if}
        </span>
      </button>
    </div>

    <aside
      class="nav"
      on:keyup={onKeyUp}
      data-ui-component="header-nav"
      class:menu-closing={!navIsOpen}
    >
      <nav aria-label="Main navigation">
        <ul class="nav__inner">
          {#each navLinks as navItem, index}
            <li class="nav__item" data-link={navItem.text.toLowerCase()}>
              {#if navItem.navigationItemUrl.linkType == "internal"}
                <a
                  rel="internal"
                  class="nav__link"
                  href="/{navItem.navigationItemUrl.internalLink.handle
                    .current}"
                  title="Go to {navItem.text} page"
                >
                  <span> {navItem.text}</span>
                </a>
              {/if}

              {#if navItem.navigationItemUrl.linkType == "external"}
                <a
                  href={navItem.navigationItemUrl.href}
                  target={navItem.navigationItemUrl.blank ? "_blank" : null}
                  rel="external"
                  class="nav__link"
                  title="Go to external link at {navItem.navigationItemUrl
                    .href}"
                >
                  <span> {navItem.text}</span>
                </a>
              {/if}
            </li>
          {/each}
        </ul>
      </nav>
    </aside>
  </header>
</sticky-header>

<style>
@-webkit-keyframes show-fixed-nav {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes show-fixed-nav {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes hide-fixed-nav {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes hide-fixed-nav {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.header-container li {
  list-style: none;
}

.header-container {
  width: 100%;
  top: 0;
  z-index: 5;
  position: fixed;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

:global(.header-container.is-fixed .header__logo) {
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  :global(.header-container.is-fixed .header__logo) {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  :global(.header-container) {
    background-color: transparent;
  }
  :global(.header-container.is-fixed) {
    box-shadow: -2px -3px 14px -6px #000;
    background-color: #fff;
  }
  :global(.header-container.is-fixed .header__inner) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  :global(.header-container.is-fixed .header__logo) {
    width: 6.5rem;
  }
}

@-webkit-keyframes show-header-logo {
  0% {
    opacity: 0;
  }
  1% {
    position: fixed;
    opacity: 0;
  }
  to {
    opacity: 1;
    position: fixed;
  }
}

@keyframes show-header-logo {
  0% {
    opacity: 0;
  }
  1% {
    position: fixed;
    opacity: 0;
  }
  to {
    opacity: 1;
    position: fixed;
  }
}

header {
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 5rem;
}

@media only screen and (min-width: 768px) {
  header {
    height: 4rem;
    background-color: transparent;
  }
}

@media only screen and (min-width: 961px) {
  header {
    max-width: 84rem;
    margin-right: auto;
    margin-left: auto;
    right: 0;
    left: 0;
    height: auto;
  }

  .is-support {
    max-width: 94rem;
  }
}

header .header__inner {
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  position: relative;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media only screen and (min-width: 768px) {
  header .header__inner {
    width: auto;
    padding: 1rem;
  }
}

@media only screen and (min-width: 961px) {
  header .header__inner {
    padding: 0.75rem;
    padding-left: 1.5rem;
  }
}

header .header__logo {
  width: 9.125rem;
  height: auto;
  z-index: 3;
  top: 0;
  position: relative;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

header .header__logo h1 {
  margin-bottom: 0;
}
header .header__logo a,
header .header__logo svg {
  width: 100%;
}
header .header__nav-toggle {
  border-radius: 100%;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  position: fixed;
  right: 0.75rem;
  background-color: #fff;
  border: 2px solid var(--primary-color);
}
@media only screen and (min-width: 768px) {
  header .header__nav-toggle {
    display: none;
  }
}
header .header__nav-toggle .header__nav-toggle-icon {
  position: relative;
  width: 1.875rem;
  display: block;
  height: 3px;
  background-color: var(--primary-color);
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
}
header .header__nav-toggle .header__nav-toggle-icon:after,
header .header__nav-toggle .header__nav-toggle-icon:before {
  content: " ";
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 100px;
  background-color: var(--primary-color);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
header .header__nav-toggle .header__nav-toggle-icon:after {
  top: -8px;
}
header .header__nav-toggle .header__nav-toggle-icon:before {
  bottom: -8px;
}
header .header__nav-toggle:active,
header .header__nav-toggle:focus {
  outline: 0;
}
header .header__nav-toggle.is-active .header__nav-toggle-icon {
  background-color: #fff;
}
header .header__nav-toggle.is-active .header__nav-toggle-icon:active,
header .header__nav-toggle.is-active .header__nav-toggle-icon:focus {
  outline: 0;
}
header .header__nav-toggle.is-active .header__nav-toggle-icon:after {
  top: 0;
  transform: rotate(45deg);
}
header .header__nav-toggle.is-active .header__nav-toggle-icon:before {
  bottom: 0;
  transform: rotate(-45deg);
}
header .nav__logo {
  width: 9.125rem;
  height: auto;
  z-index: 3;
  top: 1.5rem;
  left: 0.75rem;
  position: absolute;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media only screen and (min-width: 768px) {
  header .nav__logo {
    display: none;
  }
}
header .nav__logo h1 {
  margin-bottom: 0;
}
header .nav__logo a,
header .nav__logo svg {
  width: 100%;
}
header.is-open .nav {
  display: block;
  background-color: #fff;
}
header.is-closed .header__logo {
  -webkit-animation: hide-header-logo 0.4s cubic-bezier(0.165, 0.84, 0.44, 1)
    forwards;
  animation: hide-header-logo 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
header.is-closed .nav {
  display: none;
}
@media only screen and (min-width: 768px) {
  header.is-closed .nav {
    display: block !important;
  }
}
header .nav {
  position: fixed;
  background-color: transparent;
  width: 100%;
  padding-top: 4.5rem;
  right: 0;
  display: flex;
  height: 100%;
  top: 0;
  flex-direction: column;
  z-index: 4;
  display: none;
}
@media only screen and (min-width: 768px) {
  header .nav {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 0;
    top: 0;
    right: 0;
    height: auto;
    align-items: center;
    justify-content: center;
  }
}
header .nav__inner {
  display: flex;
  flex-direction: column;
  padding: 1.125rem 1.125rem 1.125rem 5%;
  padding-top: 2rem;
}
@media only screen and (min-width: 768px) {
  header .nav__inner {
    flex-direction: row;
    text-align: right;
    justify-content: flex-end;
    padding: 0 0 0 0;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 961px) {
  header .nav__inner {
    padding-right: 0.5rem;
  }
}

header .nav__link {
  color: var(--primary-color);
  text-decoration: none;
  text-transform: capitalize;
  font-family: var(--secondary-font-family);
  font-weight: normal;
  letter-spacing: 0.2px;
  font-size: 2rem;
  line-height: 1.25;
  font-weight: normal;
  position: relative;
}

@media only screen and (min-width: 768px) {
  header .nav__link {
    font-size: 1rem;
    line-height: 1.5;
  }
}

header .nav__link:after {
  content: " ";
  height: 3px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -0.25rem;
  background-color: #82342f;
  position: absolute;
  width: 0;
  transition: all 0.2s ease-in-out;
}

header .nav__link:focus,
header .nav__link:hover {
  color: var(--primary-color);
}

header .nav__link:focus:after,
header .nav__link:hover::after {
  width: 100%;
}

header .nav__item {
  margin-bottom: 1.125rem;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  header .nav__item {
    margin-bottom: 0;
    margin-right: 3%;
  }
}

@media only screen and (min-width: 961px) {
  header .nav__item {
    margin-bottom: 0;
    margin-right: 5%;
  }
}

@media only screen and (min-width: 768px) {
  header [data-link="launch"] {
    order: 3;
    margin-right: 0;
  }

  header [data-link="launch"] a {
    height: 2.375rem;
    width: 8.125rem;
    transition: none;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    padding: 0;
    display: block;
    text-decoration: none;
    letter-spacing: 0.5px;
    background-color: var(--primary-color);
    border-radius: 5px;
    color: #fff;
    font-family: cera_probold, sans-serif;
    font-weight: 500;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
    font-size: 0.9rem;
    line-height: 1.5;
    border: 2px solid var(--primary-color);
    margin-bottom: 0;
  }

  header [data-link="launch"] a span {
    position: relative;
    z-index: 2;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-bottom: 2px;
  }

  header [data-link="launch"] a:focus,
  header [data-link="launch"] a:hover {
    background-color: var(--primary-color);
    color: var(--primary-color);
    box-shadow: 0 7px 32px -9px rgba(177, 159, 159, 0.7);
  }

  header [data-link="launch"] a:focus:before,
  header [data-link="launch"] a:hover:before {
    transform: scale(2);
    opacity: 1;
  }

  header [data-link="launch"] a:after {
    background-color: transparent;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  header [data-link="launch"] a:after,
  header [data-link="launch"] a:before {
    content: " ";
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
  }

  header [data-link="launch"] a:before {
    background-color: #fff;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    bottom: 0;
    margin: auto;
    z-index: 1;
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
  }
}

button:focus,
button:hover {
  transform: scale(1.08);
}
nav {
  height: 100%;
}

@media screen and (min-width: 961px) {
  header.is-open .nav {
    background-color: transparent;
  }
}

.nav__item {
  margin-bottom: 1.25rem;
}
.nav__item a {
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .nav__item {
    margin-right: 3%;
    margin-bottom: 0;
  }
  .nav__item a {
    font-size: 1rem;
  }
  .nav__inner .nav__item:last-child {
    margin-right: 2%;
  }
}

@media screen and (min-width: 768px) {
  :global(header .nav__link[data-link="download varo"]) {
    height: 2.375rem;
    width: 9.375rem;
    border-radius: 48px;
    color: #fff !important;
    background-color: var(--secondary-color);
    font-family: "Be Vietnam Pro", Helvetica, sans-serif;
    position: relative;
    z-index: 1;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 7px 32px -9px rgb(28 87 141 / 50%);
  }

  :global(header .nav__link[data-link="download varo"]:hover),
  :global(header .nav__link[data-link="download varo"]:focus) {
    color: #fff;
    transform: translateY(-3px);
    box-shadow: 0 8px 24px -2px rgba(18, 92, 128, 0.4);
  }
}
</style>
